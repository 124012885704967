import { useEffect } from 'react';
import dataWatch from '../assets/fake-data/data-watch';
import Watch from '../features/watch';
import { useLocation } from 'react-router-dom';
import Header from "../components/header";

function WatchPaper() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (<>
        <Header />
        <div className='about-v2'>
            <Watch data={dataWatch} />

        </div>
    </>);
}

export default WatchPaper;