import { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
import AboutItem from './about-item';
import { useNavigate } from 'react-router-dom';
import { Grid, Stack, Typography, Box, Hidden } from '@mui/material';
import { Button } from "src/components";
import { colors } from "src/colors";
import useResponsive from 'src/hooks/useResposive';

About.propTypes = {
    data: PropTypes.array,
};

// const text = 'Shop with KAI-CHING (KAIC) to save time and money. How? Pay less if you pay with KAIC in hundreds of shops & earn extra KAIC while playing & shopping on KAIKAI Shop with KAI-CHING (KAIC) to save time and money. How? Pay less if you pay with KAIC in hundreds of shops & earn extra KAIC while playing & shopping on KAIKAI'
const text = 'KOLWatch is a revolutionary crypto project designed to bring transparency and accountability to the \nworld of cryptocurrency Key Opinion Leaders (KOLs) and influencers. It addresses the problem of \nmisleading token calls and picks by providing a platform where users can confidently stake their tokens \nin their favorite KOL Staking Pools. The project aims to reward genuine and reliable KOLs while \nidentifying and exposing fake and irresponsible actors in the industry. '

function About(props) {
    const navigate = useNavigate();
    const { data } = props;
    const isMobile = useResponsive('down', 'md');
    const [isReadMore, setIsReadMore] = useState(true);
    
    const [dataBlock] = useState(
        {
            subtitle: 'About us',
            title: 'What is kolwatch?',
            desc: `KOLWatch is a revolutionary crypto project designed to bring transparency and accountability to the \nworld of cryptocurrency Key Opinion Leaders (KOLs) and influencers. It addresses the problem of \nmisleading token calls and picks by providing a platform where users can confidently stake their tokens \nin their favorite KOL Staking Pools. The project aims to reward genuine and reliable KOLs while \nidentifying and exposing fake and irresponsible actors in the industry. `
        }
    )

    const handleClick = () => { 
        setIsReadMore(!isReadMore); 
    }; 

    return (
        <section id='about-us' className="tf-section tf-about">
            <div className="container">
                <Stack className='main' display={'flex'} flexDirection={'column'} marginBottom={'40px'} alignItems={'center'}>
                    <Stack className='section' gap={'10px'} alignItems={'center'}>
                        <Typography 
                                fontSize={20}
                                fontWeight={700}
                                color={colors.green}
                                lineHeight={'32px'}
                                textTransform={'uppercase'}
                            >
                                {dataBlock.subtitle}
                            </Typography>
                            <Typography 
                                fontSize={44}
                                fontWeight={700}
                                color={colors.white}
                                lineHeight={'52.8px'}
                                textTransform={'uppercase'}
                            >
                                {dataBlock.title}
                            </Typography>
                    </Stack>
                    <Typography 
                        fontSize={17}
                        fontWeight={400}
                        color={colors.grayB9}
                        lineHeight={'28px'}
                        textAlign={'center'}
                        whiteSpace={'pre-wrap'}
                        margin={'12px 0 30px 0'}
                        sx={{ wordSpacing: '8px' }}
                        className='desktop-text'
                    >
                        {dataBlock.desc}
                    </Typography> 
                    <Typography 
                        fontSize={17}
                        fontWeight={400}
                        color={colors.grayB9}
                        lineHeight={'28px'}
                        textAlign={'left'}
                        margin={'12px 0 30px 0'}
                        sx={{ wordSpacing: '8px' }}
                        display={'inline'}
                        className='mobile-text'
                    >
                        {isReadMore ? dataBlock.desc.slice(0, 225) : dataBlock.desc}            
                        <Box
                            className='description'
                            sx={{
                                fontSize: 14,
                                fontWeight: 700,
                                color: colors.green,
                                cursor: 'pointer',
                                textDecoration: 'underline'
                            }}
                            onClick={handleClick}
                            component="span"
                            display="inline"
                            alignItems="center"
                        >     
                            {isReadMore ? "Read more" : " Show less"}
                        </Box>
                    </Typography> 
                    {!isMobile && (
                        <Button
                            variant={"outlined"}
                            LabelColor={colors.white}
                            borderColor={colors.green}
                            borderWidth="2px"
                            bgHover={colors.green}
                            sx={{
                                width: 218,
                                height: 64,
                            }}
                            onClick={() => { navigate('/watch-paper') }}
                        >
                            <Typography
                                fontSize={16}
                                fontWeight={600}
                                lineHeight={'24px'}
                                color={colors.white}
                                textTransform={'uppercase'}
                                >
                                read whitepaper
                            </Typography>
                        </Button>
                    )}
                </Stack>
                <Grid container spacing={3} columns={16}
                    data-aos="fade-up" data-aos-duration="800"
                >
                    {
                        data.map(item => (
                            <AboutItem key={item.id} item={item} />
                        ))
                    }
                </Grid>
            </div>
        </section>
    );
}

export default About;