export const colors = {
    white: '#FFF',
    default: '#0c1226',
    blue14: '#141A31',
    blue40: '#0E2240',
    blue4C: '#162C4C',
    green22: '#22B78F',
    green2A: '#2AD2A5',
    green16: '#163159',
    green31: '#162C4C',
    green: '#14C2A3',
    green12: '#123142',
    green10: '#105252',
    green0E: '#0E3841',
    green0E22: '#0E2230',
    grayB9: '#B9B9BF',
    grayB1: '#B1BBCE',
    gray49: '#49697A',
    gray3D: '#3D5579',
    gray2B: '#2B426A',
    black: '#042860',
    green0E2: '#0E2331',
    red: '#F04F4F'
};