import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: 14,
          fontWeight: 400,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          if (ownerState?.variant === 'contained') {
            return {
              border: '2px solid #0E3841',
              background: "#123142",
              borderRadius: 0,
              textTransform: 'none',
              justifyContent: 'space-between',
              '&:hover': {
                background: "#123142",
                border: '2px solid white',
              }

            }
          }
          return { textTransform: 'none', }

        }
      }
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          color: "white",

          "& .MuiPaginationItem-root": {
            color: "#B1BBCE",
          },
          "& .Mui-selected": {
            backgroundColor: "#123142",
            color: "white",
            borderRadius: 0,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        svg: {
          margin: 0,
        },
        a: {
          textDecoration: "none",
        },
      },
    },
  },
  typography: {
    // fontFamily: "Inter !important",
    fontFamily: "'Chakra Petch', sans-serif !important",
    // fontFamily: "Roboto !important",
    allVariants: {
      color: "#363537",
    },
    h1: {
      fontSize: 28,
      fontWeight: 600,
      color: "#274052",
    },
  },
  palette: {
    primary: {
      main: "#884EB9",
    },
  },
});
