import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { dataPrivacy } from '../assets/fake-data/privacy-data';
import Privacy from '../features/privacy';
import Header from "../components/header";

function PrivacyPage() {

    // const location = useLocation();

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location]);

    return (<>
        <Header />
        <div className='privacy-v2'>
            <Privacy data={dataPrivacy} />
        </div>
    </>);
}

export default PrivacyPage;