const dataAbout = [
    {
        id: 1,
        title: 'KOL VERIFICATION AND REPUTATION SYSTEM',
        desc: 'KOLWatch employs a rigorous verification process to onboard legitimate KOLs and influencers. This process includes verifying their track record, credibility, and historical performance in the crypto space. Each KOL is assigned a reputation score that reflects their reliability and accuracy over time.',
    },
    {
        id: 2,
        title: 'Staking, Betting, and Performance Pools',
        desc: "Users can engage in various activities within the ecosystem, including staking, betting, and participating in performance pools linked to Key Opinion Leaders (KOLs). These pools offer a wide range of opportunities for users to leverage their tokens based on their confidence in a particular KOL's abilities and actions",
    },
    {
        id: 3,
        title: 'SOCIAL PLATFORM AND KOL ENGAGEMENT',
        desc: 'Within KOLWATCH, each Key Opinion Leader (KOL) enjoys a dedicated profile on our social platform Dapp. This profile empowers KOLs to regularly share news and high-quality content, and in return, our platform rewards them with monthly USDT payments and exclusive prizes. \nFurthermore, our social media platform invites everyone to create an account, fostering intimate and personal interactions with their preferred KOLs, creating a more engaged and connected community.',
    },
    {
        id: 4,
        title: 'IRL office and headquarters + KOLWATCH DUBAI',
        desc: 'Dubai has been meticulously selected as our corporate headquarters, providing a tangible touchpoint for KOLWATCH. Visitors have the privilege of accessing our state-of-the-art studios for content creation, participating in our weekly podcasts, or simply indulging in the KOL Lounge, embracing the full spectrum of experiences at the KOLWATCH headquarters. Additionally, we host monthly meetings to engage with the community and our esteemed advisors, facilitating open discourse on the present and the future of KOLWATCH. Your enthusiasm mirrors our own, and we eagerly anticipate what lies ahead.',
    },
]

export default dataAbout;