import React, { FC, useMemo, useState, useEffect } from 'react';
import { Dialog, Typography, Stack, Divider, AppBar, Toolbar, IconButton, Slide, Hidden, Box } from '@mui/material';
import { colors } from "src/colors";
import { Button } from "..";
import { Close } from 'src/assets/icons';
import { WhiteClose } from 'src/assets/icons';
import { TransitionProps } from '@mui/material/transitions';
import useResponsive from '../../hooks/useResposive';

const deadline = "November, 12, 2023, 12:00:00";
const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const Transition = React.forwardRef((
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) => {
    return <Slide direction='left' ref={ref} {...props} />
  });

const LaunchModal: FC<IProps> = ({ open, onClose }) => {

    const parsedDeadline = useMemo(() => Date.parse(deadline), [deadline]);
    const [time, setTime] = useState(parsedDeadline - Date.now());

    useEffect(() => {
        const interval = setInterval(
            () => setTime(parsedDeadline - Date.now()),
            1000,
        );

        return () => clearInterval(interval);
    }, [parsedDeadline]);

    return (<>
        <Dialog
            open={open.desktop}
            onClose={onClose}
            scroll={'paper'}
            fullWidth={true}
            sx={{
                '& div.MuiDialog-paper': { 
                    maxWidth: 'max-content',
                    borderRadius: 0,
                    background: 'transparent',
                    position: 'relative',
                    overflow: 'visible'
                }
            }}
        >
            
                <Stack 
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '-52px',
                        top: '-52px'
                    }}
                    onClick={onClose}
                >
                    <Close />
                </Stack>
                <Stack sx={{
                    backgroundColor: colors.green0E22,
                    border: `2px solid ${colors.green22}`,
                }} p={'40px 50px'}>
                    <Typography
                        fontSize={44}
                        fontWeight={700}
                        lineHeight={'52.8px'}
                        color={colors.green2A}
                        textTransform={'uppercase'}
                        marginBottom={'22px'}
                    >
                        Launch in
                    </Typography>
                    <Stack sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '48px',
                        marginBottom: '35px'
                    }}>
                        {Object.entries({
                            Days: time / DAY,
                            Hours: (time / HOUR) % 24,
                            Minutes: (time / MINUTE) % 60,
                            Seconds: (time / SECOND) % 60,
                        }).map(([label, value]) => (<>
                            <Stack sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                                <Typography
                                    fontSize={44}
                                    fontWeight={700}
                                    lineHeight={'52.8px'}
                                    color={colors.white}
                                >
                                    {`${Math.floor(value)}`.padStart(2, "0")}
                                </Typography>
                                
                                <Typography
                                   fontSize={24}
                                   fontWeight={500}
                                   lineHeight={'52.8px'}
                                   textTransform={'uppercase'}
                                   color={colors.white}
                                >
                                    {label}
                                </Typography>
                            </Stack>
                            {label !== 'Seconds' && (
                                <Divider orientation="vertical" variant="middle" flexItem={true} 
                                    sx={{ 
                                        height: '42.61px !important',
                                        borderWidth: '1px', 
                                        borderColor: colors.white,
                                        opacity: 1,
                                        marginTop: 0,
                                        marginBottom: '12px'
                                    }} 
                                />
                            )}
                        </>))}
                        
                    </Stack>
                    {/* <Button
                        variant={"contained"}
                        href="https://dapp.kolwatch.io"
                        backgroundColor={colors.green}
                        LabelColor={colors.black}
                        bgHover={colors.green}
                        sx={{
                            height: 64,
                        }}
                    >
                        <Typography
                            fontSize={16}
                            fontWeight={600}
                            lineHeight={'24px'}
                            color={colors.white}
                            textTransform={'uppercase'}
                            >
                            Demo
                        </Typography>
                    </Button> */}
                </Stack>
        </Dialog>
        <Dialog 
            open={open.mobile} 
            fullScreen 
            fullWidth 
            TransitionComponent={Transition}
        >
            <AppBar position='static' sx={{background: colors.blue14, color: colors.white, padding: '10px 0 10px 0'}}>
              <Toolbar sx={{ gap: '10px', minHeight: 0 }}>
                  <IconButton 
                      onClick={onClose}
                  >
                       <WhiteClose />           
                  </IconButton> 
                  
                  <Typography
                      fontSize={14}
                      fontWeight={600}
                      lineHeight={'24px'}
                      color={colors.white}
                      textTransform={'uppercase'}
                  >
                      Launch time
                  </Typography>             
              </Toolbar>
            </AppBar>
            <Box 
                width='100%'
                height='100%'
                p={'24px'}
                bgcolor={colors.default}
                flexDirection='column'
                display='flex'
            >
              <Stack paddingTop={'15px'}>
                  <Stack
                    gap={'24px'}
                    justifyContent={'center'}
                  >
                    <Typography
                        fontSize={32}
                        fontWeight={700}
                        lineHeight={'32px'}
                        color={colors.green2A}
                        textTransform={'uppercase'}
                        marginBottom={'22px'}
                        textAlign={'center'}
                    >
                        Launch in
                    </Typography>
                    <Stack sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '25px',
                        marginBottom: '35px',
                        justifyContent: 'center',
                        width: '100%'
                    }}>
                        {Object.entries({
                            Days: time / DAY,
                            Hours: (time / HOUR) % 24,
                            Minutes: (time / MINUTE) % 60,
                            Seconds: (time / SECOND) % 60,
                        }).map(([label, value]) => (<>
                            <Stack sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                                <Typography
                                    fontSize={32}
                                    fontWeight={700}
                                    lineHeight={'52.8px'}
                                    color={colors.white}
                                >
                                    {`${Math.floor(value)}`.padStart(2, "0")}
                                </Typography>
                                
                                <Typography
                                   fontSize={14}
                                   fontWeight={500}
                                   lineHeight={'52.8px'}
                                   textTransform={'uppercase'}
                                   color={colors.white}
                                >
                                    {label}
                                </Typography>
                            </Stack>
                            {label !== 'Seconds' && (
                                <Divider orientation="vertical" variant="middle" flexItem={true} 
                                    sx={{ 
                                        height: '42.61px !important',
                                        borderWidth: '1px', 
                                        borderColor: colors.white,
                                        opacity: 1,
                                        marginTop: 0,
                                        marginBottom: '12px'
                                    }} 
                                />
                            )}
                        </>))}
                        
                    </Stack>
                    {/* <Button
                        variant={"contained"}
                        href="https://dapp.kolwatch.io"
                        backgroundColor={colors.green}
                        LabelColor={colors.black}
                        bgHover={colors.green}
                        sx={{
                            height: 64,
                        }}
                    >
                        <Typography
                            fontSize={16}
                            fontWeight={600}
                            lineHeight={'24px'}
                            color={colors.white}
                            textTransform={'uppercase'}
                            >
                            Demo
                        </Typography>
                    </Button> */}
                  </Stack>
              </Stack>
            </Box>
        </Dialog>
    </>);
};

export interface IProps {
    open: any;
    onClose?: () => void;
}

export default LaunchModal;