import React, { useEffect } from 'react';
import Condition from '../features/condition';
import { useLocation } from 'react-router-dom';
import dataCondition from '../assets/fake-data/data-condition';
import Header from "../components/header";

function TermsAndCondition() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (<>
        <Header />
        <div className='condition-v2'>
            <Condition data={dataCondition} />
        </div>
    </>);
}

export default TermsAndCondition;