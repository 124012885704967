import { useState } from 'react';
import './styles.scss';
import { Stack, Typography, Fade } from '@mui/material';
import { colors } from "../../../colors";

interface Props {
    item?: any
}

function ProjectItem(props: Props) {

    const { item } = props;
    const [open, setOpen] = useState<boolean>(false);

    return (
        <button 
            className="img-box" 
            onMouseOver={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)} 
        >
            <div 
                className='col textme' 
                style={{ 
                    width: '100%', 
                    height: '285px', 
                    backgroundColor: item.background,
                    position: 'relative'
                }}
            >
                <Stack height={'50%'} justifyContent={'end'}>
                    <Typography 
                        fontSize={76}
                        fontWeight={700}
                        color={'#000'}
                        lineHeight={'96px'}
                    >
                        {item.percent}
                    </Typography>
                </Stack>
                <Typography 
                    fontSize={item.id === 4 ? 23 : (item.id === 5 || item.id === 6) ? 25 : 38}
                    fontWeight={700}
                    color={'#000'}
                    lineHeight={item.id === 4 ? '28px' : (item.id === 5 || item.id === 6) ? '28.5px' : '37px'}
                    textTransform={'uppercase'}
                    whiteSpace={'pre-wrap'}
                    height={'50%'}
                    paddingTop={item.id === 1 ? '30px' : '10px'}
                >
                    {item.subTitle}
                </Typography>
                <Fade in={open}>
                    <Stack 
                        sx={{
                            backgroundColor: 'rgba(44, 47, 45, .96)',
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography 
                            fontSize={20}
                            fontWeight={700}
                            color={colors.white}
                            lineHeight={'32px'}
                            whiteSpace={'pre-wrap'}
                            textTransform={'uppercase'}
                        >
                            {item.description}
                        </Typography>
                    </Stack>
                </Fade>
            </div>
            {/* <Stack className="content">
                <Typography 
                    fontSize={20}
                    fontWeight={700}
                    color={colors.white}
                    lineHeight={'32px'}
                    >
                    {item.title}
                </Typography>
            </Stack> */}
        </button>
    );
}

export default ProjectItem;