export const dataPrivacy = `**Privacy Policy KOL WATCH
*Last Updated: 2023/09/27
At KOL WATCH, we are dedicated to safeguarding your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your data when you use our website, products, and services. By accessing or using KOL WATCH, you consent to the practices described in this Privacy Policy.
**1. Information We Collect:
**1.1. Personal Information: When you engage with our website and services, we may collect personal information, including:
- Your name
- Email address
- Contact information
- Billing information (if applicable)
- User-generated content (such as comments or reviews)
- Other information you provide voluntarily
**1.2. Usage Data: We automatically collect information about your interactions with KOL WATCH, including:
- IP address
- Browser type
- Device information
- Pages visited
- Time and date of your visit
- Referring and exit pages
**1.3. Cookies and Similar Technologies: We use cookies and similar tracking technologies to enhance your experience on our website. Cookies are small files stored on your device that collect information about your browsing habits. You can manage your cookie preferences through your browser settings.
**2. How We Use Your Information:
**2.1. To Provide Services: We use your personal information to:
- Provide you with access to our website and services
- Facilitate account registration and authentication
- Process transactions (if applicable)
- Respond to your inquiries and provide customer support
**2.2. Communication: We may use your contact information to:
- Send you updates, newsletters, marketing materials, and important notices related to KOL WATCH
- Communicate with you about your account or transactions
- Respond to your requests or feedback
You can opt out of promotional communications at any time.
**2.3. Improve Our Services: We may use aggregated and anonymized data to:
- Analyze user behavior and preferences
- Enhance our website and services
- Develop new features and offerings
**2.4. Legal Compliance: We may use your information to:
- Comply with applicable laws, regulations, or legal processes
- Respond to legal requests, including from law enforcement agencies
- Protect our rights, privacy, safety, or property, and that of our users and partners
**3. Information Sharing:
**3.1. Third-Party Service Providers: We may share your information with trusted third-party service providers who assist us in:
- Operating our website and services
- Processing payments (if applicable)
- Providing analytics and insights
- Conducting marketing campaigns
These providers are contractually obligated to maintain the confidentiality and security of your information.
**3.2. Legal Requirements: We may share your information when required by:
- Applicable laws, regulations, or legal processes
- Government authorities or law enforcement agencies
- To enforce our Terms of Service or protect our rights, privacy, safety, or property, and that of our users and partners
**3.3. Business Transfers: In the event of a merger, acquisition, or sale of all or part of our assets, your personal information may be transferred to the acquiring entity, subject to this Privacy Policy.
**4. Data Security:
We employ reasonable security measures to protect your data from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no data transmission over the internet is entirely secure, and we cannot guarantee the absolute security of your information.
**5. Your Choices:
**5.1. Access and Correction: You have the right to access and correct your personal information stored by us. You can do this by contacting us at [contact email].
**5.2. Data Deletion: You can request the deletion of your personal information, subject to legal obligations and our legitimate interests.
**5.3. Opt-Out: You can opt out of receiving promotional communications from us by:
- Following the unsubscribe instructions in our emails
- Contacting us at [contact email]
**6. Changes to This Privacy Policy:
We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page, and the date of the latest revision will be indicated at the top.
**7. Contact Us:
If you have any questions or concerns about this Privacy Policy or your personal information, please contact us at [contact email].
By using KOL WATCH, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and sharing of your information as described herein`;
