import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./App.scss";
import { store } from "./store/store";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";
import ScrollToTop from "./ScrollToTop";
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ScrollToTop />
          <App />
        </StyledEngineProvider>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
