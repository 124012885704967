import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
import RoadMapItem from './roadmap-item';
import useResponsive from 'src/hooks/useResposive';

RoadMap.propTypes = {
    data: PropTypes.array,
};

function RoadMap(props) {

    const { data } = props;
    const isMobile = useResponsive('down', 'sm');
    const [dataBlock] = useState(
        {
            subtitle: 'ROADMAP',
            title: 'KOLWATCH Timeline'
        }
    )

    return (
        <section id='roadmap' className="tf-section mb-100 tf-roadmap">
            <div className="overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title mb-30" style={{
                            marginBottom: isMobile ? '20px' : '30px'
                        }} data-aos="fade-up" data-aos-duration="800">
                            <p className="h8 sub-title text-center">{dataBlock.subtitle}</p>
                            <h4 className="title">{dataBlock.title}</h4>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="roadmap">

                            {
                                data.map(item => (
                                    <RoadMapItem key={item.id} item={item} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default RoadMap;