const dataRoadmap = [
  {
    id: 3,
    time: "Q1 2024",
    list: [
      { text: "Website 2.0" },
      { text: "Dapp Development" },
      { text: "Dubai Headquarters" },
      { text: "Introduction of Final Version of KOLWatch Dapp" },
      { text: "Multiple Upgrade Features" },
      { text: "Trade with Influencers" },
      { text: "Global Expansion" },
      { text: "User Governance" },
    ],
    positon: "left",
    style: "normal",
  },
  {
    id: 4,
    time: "Q4 2023",
    list: [
      { text: "Website Development" },
      { text: "Dapp Development" },
      { text: "Launch Version Demo of Dapp" },
      { text: "Token Launch" },
      { text: "Token Audit" },
      { text: "Onboarding KOLs and Influencers" },
      { text: "Community Building" },
    ],
    positon: "right",
    style: "normal",
  },
  {
    id: 1,
    time: "Q3 2024",
    list: [
      {
        text: "KOLWatch Social Media Platform Launch",
      },
      {
        text: "KOLWatch Dubai Party",
      },
      {
        text: "Token Burn Events",
      },
      {
        text: "Strategic Partnerships",
      },
      { text: "Ecosystem Expansion" },
      { text: "Strategic Acquisitions" },
      { text: "Educational Initiatives" },
    ],
    positon: "left",
    style: "normal",
  },
  {
    id: 2,
    time: "Q2 2024",
    list: [
      { text: "Social Media Awareness" },
      { text: "Content Creation for TikTok and Instagram" },
      { text: "Bi-Weekly Podcast with Influencers" },
      { text: "Token Burn Events" },
      { text: "KOLWatch Dubai Party" },
    ],
    positon: "right",
    style: "normal",
  },
];

export default dataRoadmap;
