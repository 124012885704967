import '../scss/components/section.scss';
import '../scss/components/box.scss';
import Slider from '../components/slider';
import dataSlider from '../assets/fake-data/data-slider';
import About from '../features/about';
import Project from '../features/project';
import dataProject from '../assets/fake-data/dataProject';
import dataAbout from '../assets/fake-data/data-about';
import RoadMap from '../features/roadmap';
import dataRoadmap from '../assets/fake-data/data-roadmap';
import Work from '../features/work';
import dataWork from '../assets/fake-data/data-work';
import Partner from '../features/partner';
import Header from "../components/header";

function HomeOne(props) {

    return (<>
        <Header />
        <div className='home-1'>
            <Slider data={dataSlider} />

            <About data={dataAbout} />

            <Work data={dataWork} />

            <Partner />

            <Project data={dataProject} />

            <RoadMap data={dataRoadmap} />
        </div>
    </>);
}

export default HomeOne;