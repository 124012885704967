import React, { useState } from "react";
import PropTypes from "prop-types";

import "./styles.scss";
import ProjectItem from "./project-item";

Project.propTypes = {
  data: PropTypes.array,
};

function Project(props) {
  const { data } = props;
  const [dataBlock] = useState({
    subtitle: "tokenomics",
    title: "KOLWatch Token Dynamics",
  });

  return (
    <section id="tokenomics" className="tf-section tf-project">
      <div className="container">
        <div className="col-md-12">
          <div className="tf-title" data-aos="fade-up" data-aos-duration="800">
            <p className="h8 sub-title text-align-center">
              {dataBlock.subtitle}
            </p>
            <h4 className="title">{dataBlock.title}</h4>
          </div>
        </div>
        <div className="row justify-content-center gap-4 mb-40">
          {data.map((item, index) => (
            <ProjectItem key={index} item={item} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Project;
