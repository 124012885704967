import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss'

Condition.propTypes = {
    data: PropTypes.array,
};

function Condition(props) {

    const { data } = props;

    const [dataBlock] = useState(
        {
            subtitle: 'Terms and condition',
            title: 'KOLWatch',
        }
    )
   
    return (
        <section className="tf-section tf-condition">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="content-condition mobie-40" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title">
                                <p className="h8 sub-title">{dataBlock.subtitle}</p>
                                <h4 className="title">{dataBlock.title}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row text" data-aos="fade-up" data-aos-duration="800">
                <div className="container">
                    <div className="row">
                        <div className="">
                            {
                                data.slice(0,11).map(item => (<>
                                    <p>{item.title}</p>
                                    <p>{item.content1}</p>
                                    <p>{item.content2}</p>
                                    <p>{item.content3}</p>
                                </>))
                            }
                        </div>
                    </div>
                    <div className="row">
                        <p style={{
                            whiteSpace: 'pre-wrap'
                        }}>
                            {`If you have any questions or concerns about these T&C, please contact us Via our costumer support methods and channels.\nBy using KOL Watch, you acknowledge that you have read and understood these Terms and Conditions and agree to be bound by them.`}
                        </p>
                    </div>        
                </div>
            </div>
        </section >
    );
}

export default Condition;