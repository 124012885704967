import { useEffect } from "react";
import AOS from "aos";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/footer";
import "../src/assets/font/font-awesome.css";
import routes from "./pages";
import Page404 from "./pages/404";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <>
      <Routes>
        {routes.map((data, idx) => (
          <Route key={idx} path={data.path} element={data.component} />
        ))}
        <Route path="*" element={<Page404 />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
