import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Stack } from '@mui/material';
import { colors } from "../../../colors";
import useResponsive from 'src/hooks/useResposive';
import bgAboutItem from 'src/assets/images/background/bgAboutItem.png';

AboutItem.propTypes = {
    item: PropTypes.object,
};

function AboutItem(props) {

    const {item, status} = props;
    const isMobile = useResponsive('down', 'md');


    return (
        <Grid item xs={isMobile ? 16 : 8} key={item.id} 
            // className={`box-text corner-box ${item.class}`}
        >
           <Stack sx={{
                backgroundColor: colors.green0E22,
                border: `1px solid rgba(34, 183, 143, .5)`,
                padding: status === 'watch-paper' ? '50px 18px 17.96px 18px' : '17px 18px 17.96px 18px',
                gap: '15.98px',
                height: '100%',
                backgroundImage: status === 'watch-paper' ? `url(${bgAboutItem})` : undefined,
                backgroundSize: 'cover'
                // minHeight: '365px',
                // overflow: "hidden"
           }}>
                <div className="h7">{item.title}</div>
                <p style={{whiteSpace: 'pre-wrap'}}>{item.desc}</p>
           </Stack>
        </Grid>
    );
}

export default AboutItem;