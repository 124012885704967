import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import WorkItem from './work-item';
import { Box, Typography } from '@mui/material';
import { colors } from "src/colors";

Work.propTypes = {
    data: PropTypes.array,
};

function Work(props: any) {

    const { data } = props;
    const [isReadMore, setIsReadMore] = useState<boolean>(true);
    const [dataBlock] = useState({
        subtitle: 'Staking Pools',
        title: 'Our Ecosystem of Pools',
        desc: `In staking pools, users have the opportunity to anchor their tokens alongside a KOL they have faith in. This is both a declaration of trust and a genuine investment in that KOL's decisions and insights. The amount of tokens staked within a pool directly impacts the profit potential of that KOL. Consequently, users have the potential to earn profits influenced by the investment decisions and successes of the associated KOL. `
    })

    const handleClick = () => { 
        setIsReadMore(!isReadMore); 
    }; 

    return (
        <section id='staking-pools' className="tf-section how-we-work">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title st2 mb-60" data-aos="fade-up" data-aos-duration="800">
                            <p className="h8 sub-title">{dataBlock.subtitle}</p>
                            <h4 className="title m-b17">{dataBlock.title}</h4>
                            {/* <p className="p-r40 description">{dataBlock.desc} </p> */}
                            <Typography 
                                fontSize={17}
                                fontWeight={400}
                                color={colors.grayB9}
                                lineHeight={'28px'}
                                textAlign={'center'}
                                whiteSpace={'pre-wrap'}
                                margin={'12px 0 30px 0'}
                                sx={{ wordSpacing: '8px' }}
                                className='p-r40 desktop-text'
                            >
                                {dataBlock.desc}
                            </Typography> 
                            <Typography 
                                color={colors.grayB9}
                                lineHeight={'28px'}
                                textAlign={'left'}
                                margin={'12px 0 30px 0'}
                                sx={{ wordSpacing: '8px' }}
                                display={'inline'}
                                className='mobile-text'
                            >
                                {isReadMore ? dataBlock.desc.slice(0, 203) : dataBlock.desc}            
                                <Box
                                    className='description'
                                    sx={{
                                        color: colors.green,
                                        cursor: 'pointer',
                                        textDecoration: 'underline'
                                    }}
                                    onClick={handleClick}
                                    component="span"
                                    display="inline"
                                    alignItems="center"
                                >     
                                    {isReadMore ? "Read more" : " Show less"}
                                </Box>
                            </Typography> 
                        </div>

                    </div>

                    {
                        data.map((item: any) => (
                            // <div className="col-xl-4 col-lg-6 col-md-6">
                            // <div className="wrap-about" data-aos="fade-up" data-aos-duration="800">
                            <div key={item.id} className="mb14 col-xl-4 col-lg-6 col-md-12 col-12" data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
                                <WorkItem item={item} />
                                {/* </div> */}
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default Work;