import React, { useState, useEffect } from "react";
import menus from "../../pages/menu";
import "./styles.scss";
import { headerLogo } from "src/assets";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Box,
  Stack,
  Typography,
  Hidden,
  Dialog,
  Slide,
  IconButton
} from "@mui/material";
import { Container, Button } from "..";
import { colors } from "../../colors";
import SearchField from "../../components/Search/Search";
import { MenuButton } from "../../components/button/MenuButton";
import dataMenu from "../../assets/fake-data/data-menu";
import LaunchModal from './LaunchModal';
import { TransitionProps } from '@mui/material/transitions';
import { HamburgerMenu, WhiteClose } from 'src/assets/icons';
import useResponsive from '../../hooks/useResposive';
interface IProps {
  restricted?: boolean;
}

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) => {
  return <Slide direction='left' ref={ref} {...props} />
});

const Header: React.FC<IProps> = ({ restricted }) => {
  const navigate = useNavigate();
  const [scroll, setScroll] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<any>({
    mobile: false, desktop: false
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isMobile = useResponsive('down', 'sm');
  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 300);
    });
    return () => {
      setScroll(false);
    };
  }, []);

  const [menuActive, setMenuActive] = useState<boolean>(false);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const handleDropdown = (index: any) => {
    setActiveIndex(index);
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack className="header_menu_main">
        <Stack zIndex={12} sx={{
          backgroundColor: colors.default,
          position: scroll ? 'fixed' : 'static',
          width: '100%'
        }}>
          <Stack
            // className="menu_container" 
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px 16px'
            }}
          >
            <Box sx={{
              flexGrow: 1,
              display: 'flex',
              gap: '10px'
            }}>
              <HamburgerMenu onClick={(e: any) => handleClick(e)} />
              <Link to='/'>
                <Box sx={{
                  height: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <img src={headerLogo} alt="KOLWatch" width={'90.26px'} height={'15.23px'} />
                </Box>
              </Link>
            </Box>
            <Button
              variant={"outlined"}
              LabelColor={colors.white}
              borderColor={colors.green}
              borderWidth="2px"
              bgHover={colors.green}
              onClick={() => { window.open("https://dapp.kolwatch.io", '_blank'); }}
              sx={{
                width: '158px',
                height: '38px'
              }}
            // onClick={() => setIsOpen({ mobile: true, desktop: false }) }
            >
              <Typography
                fontSize={16}
                fontWeight={600}
                color={colors.white}
                lineHeight={'24px'}
                textTransform={'uppercase'}
              >
                Launch dapp
              </Typography>
            </Button>
          </Stack>
        </Stack>
        <Dialog id={id} open={open} fullScreen fullWidth TransitionComponent={Transition} >
          <AppBar position='static' sx={{ background: colors.blue14, color: colors.white, padding: '10px 0 10px 0' }}>
            <Toolbar sx={{ gap: '10px', minHeight: 0 }}>
              <IconButton
                onClick={handleClose}
              >
                <WhiteClose />
              </IconButton>

              <Typography
                fontSize={14}
                fontWeight={600}
                lineHeight={'24px'}
                color={colors.white}
                textTransform={'uppercase'}
              >
                Menu
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            width='100%'
            height='100%'
            p={'24px'}
            bgcolor={colors.default}
            flexDirection='column'
            display='flex'
          >
            <Stack paddingTop={'15px'}>
              <Stack
                className="dialog-menu-container"
                gap={'24px'}
              >
                {menus.map((data: any, idx) => (
                  <a href={`${data.hash}`}>
                    <Typography
                      color={activeIndex === idx ? colors.green : colors.white}
                      sx={{ cursor: 'pointer' }}
                      textTransform={'uppercase'}
                      className="title"
                      onClick={() => handleDropdown(idx)}
                    >
                      {data.name}
                    </Typography>
                  </a>
                ))}
              </Stack>
            </Stack>
          </Box>
        </Dialog>
        <LaunchModal
          open={isOpen}
          onClose={() => setIsOpen({ mobile: false, desktop: false })}
        />
      </Stack>
      <header
        id="header_main"
        className={`header ${scroll ? "is-fixed" : ""}`}
      >
        <div className="container">
          <Stack sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <div className="header__logo">
              <a href="/">
                <img src={headerLogo} alt="KOLWatch" />
              </a>
            </div>
            <nav
              id="main-nav"
              className={`main-nav ${menuActive ? "active" : ""}`}
            >
              <ul id="menu-primary-menu" className="menu">
                {menus.map((data, idx) => (
                  <li
                    key={idx}
                    onClick={() => handleDropdown(idx)}
                    className={`menu-item ${activeIndex === idx ? "active" : ""
                      }`}
                  >
                    <a href={`${data.hash}`}>{data.name}</a>
                  </li>
                ))}
              </ul>
            </nav>
            <Button
              variant={"outlined"}
              LabelColor={colors.white}
              borderColor={colors.green}
              borderWidth="2px"
              bgHover={colors.green}
              sx={{
                width: '211px',
                height: '48px'
              }}
              onClick={() => { window.open("https://dapp.kolwatch.io", '_blank'); }}

            // onClick={() => setIsOpen({ mobile: false, desktop: true }) }
            // onClick={() => setIsOpen({ mobile: false, desktop: true }) }
            >
              <Typography
                fontSize={16}
                fontWeight={600}
                color={colors.white}
                lineHeight={'24px'}
                textTransform={'uppercase'}
              >
                Launch dapp
              </Typography>
            </Button>
          </Stack>
        </div>
      </header>
    </>
  );
};

export default Header;
