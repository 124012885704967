import { useState } from "react";
// import { Swiper as SwiperProps } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import dataPartner from "../../assets/fake-data/data-partner";
import { colors } from "src/colors";
import { Stack, Typography } from "@mui/material";
import { Container, Button } from "src/components";
import './styles.scss'

export default function Partner() {

  // const [swiper, setSwiper] = useState<SwiperProps>();
  // const [isEnd, setIsEnd] = useState<boolean | undefined>(undefined);
  const [isViewAll, setIsViewAll] = useState<boolean>(true);

  return (
    <Stack
      sx={{
        paddingY: "90px",
        backgroundColor: colors.blue14,
      }}
      className="partner-main"
    >
      <Container>
        <Stack gap={"50px"} className="partner-container">
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography
              fontSize={20}
              fontWeight={700}
              color={colors.green}
              lineHeight={"32px"}
              textTransform={"uppercase"}
              className="title"
            >
              kolwatch
            </Typography>
            <Typography
              fontSize={44}
              fontWeight={700}
              color={colors.white}
              lineHeight={"52px"}
              textTransform={"uppercase"}
              className="sub-title"
            >
              Featured on
            </Typography>
          </Stack>
          <div className="row desktop">

            <div className="col-md-12 items">
              <div
                className="item-parner"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                {dataPartner.slice(0, 5).map((item, idx) => (
                  <div className="image d-flex justify-content-center">
                    <img src={item.img} alt="Crybox" />
                  </div>
                ))}
              </div>
            </div>
            <div className='col-xl-12 col-md-12 view-mobile' style={{
              padding: isViewAll ? '0 15px' : 0
            }}>
              {isViewAll ? <Button
                  variant={"outlined"}
                  LabelColor={colors.white}
                  borderColor={colors.green}
                  borderWidth="2px"
                  // bgHover={colors.green}
                  sx={{
                      width: '100%',
                      height: 56,
                  }}
                  onClick={() => setIsViewAll(!isViewAll)}
              >
                <Typography
                    fontSize={15}
                    fontWeight={600}
                    lineHeight={'19.5px'}
                    color={colors.white}
                    textTransform={'uppercase'}
                >
                      View all
                </Typography>
              </Button>
              :
              <div className="col-md-12">
                <div className="item-parner">
                  {dataPartner.slice(5, 10).map((item, idx) => (
                    <div className="image d-flex justify-content-center">
                      <img src={item.img} alt="Crybox" />
                    </div>
                  ))}
                </div>
              </div>
              }
            </div>      
            <div className="col-md-12 no-mobile">
              <div className="item-parner">
                {dataPartner.slice(5, 10).map((item, idx) => (
                  <div className="image d-flex justify-content-center">
                    <img src={item.img} alt="Crybox" />
                  </div>
                ))}
              </div>
            </div>
            
          </div>

          <div className="row tablet">
            <div className="col-md-12">
              <div
                className="item-parner"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                {dataPartner.slice(0, 3).map((item, idx) => (
                  <div className="image d-flex justify-content-center">
                    <img src={item.img} alt="Crybox" />
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-12">
              <div 
                className="item-parner"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                {dataPartner.slice(3, 6).map((item, idx) => (
                  <div className="image d-flex justify-content-center">
                    <img src={item.img} alt="Crybox" />
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-12">
              <div 
                className="item-parner"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                {dataPartner.slice(6, 9).map((item, idx) => (
                  <div className="image d-flex justify-content-center">
                    <img src={item.img} alt="Crybox" />
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-12">
              <div
                data-aos="fade-up"
                data-aos-duration="800"
                style={{ paddingTop: '67px', marginTop: 0 }}
              >
                {dataPartner.map((item, index) => (
                  (dataPartner.length - 1 === index) &&
                  <div className="image d-flex justify-content-center">
                    <img src={item.img} alt="Crybox" />
                  </div>
                ))}
              </div>
            </div>
          </div>

        </Stack>
      </Container>
    </Stack>
  );
}
